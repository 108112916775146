.logo {
  font-size: 50px;
}

@media only screen and (max-width: 600px) {
  .logo {
    font-size: 2em;
  }
}

.light {
  font-weight: 300;
}

.bold {
  font-weight: 700;
}
