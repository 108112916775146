img {
  max-width: 100%;
  height: auto;
}

.appName {
  justify-content: center;
  display: flex;
  padding: 15px;

  font-weight: bold;
  text-transform: uppercase;
}

.descriptionContainer {
  justify-content: center;
  display: flex;
  padding: 15px;
}
.description {
  width: 100%;
  max-width: 600px;
}

.thumbnailContainer {
  padding: 15px;
  display: flex;
  justify-content: center;
}

.innerContainer {
  display: flex;
  max-width: 1280px;
}

.thumbnail {
  flex: 1;
  padding: 0 15px;
}

@media only screen and (max-width: 600px) {
  .innerContainer {
    display: block;
  }
  .thumbnailContainer {
    display: block;
  }
  .thumbnail {
    flex-basis: 100%;
  }
}

.thumbnail img {
  width: 100%;
  height: auto;
}

.badgeContainer {
  display: flex;
  justify-content: center;
  padding: 15px;
}

.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 200px;
  height: auto;
}

.socialContainer {
  display: flex;
  justify-content: center;
  padding: 15px;
}

.socialIcon {
  color: #aaaaaa;
}
