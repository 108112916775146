.subFooterContainer {
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  justify-content: center;
}

.link {
  font-size: 0.8em;
}

.link:link,
.link:visited,
.link:hover,
.link:active {
  color: #aaaaaa;
}
